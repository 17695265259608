// 服务中心
<template>
  <div class="serve content" v-if="$isPc">
    <router-view />
  </div>
  <el-container v-else>
    <el-aside width="110px" class="personal-navs pb-50">
      <el-menu :default-active="activeIndex" router class="el-menu-vertical-demo ptb-32" background-color="#FFFFFF"
        text-color="#8490AD" active-text-color="#2974EF">
        <template v-for="(nav, navIndex) in navs">
          <el-submenu v-if="nav.children && nav.children.length" :key="nav.route_url" :index="nav.route_url">
            <template slot="title">{{ nav.name }}</template>
            <el-menu-item v-for="(item, index) in nav.children" :key="index" :index="item.route_url">
              {{ item.name }}
            </el-menu-item>
          </el-submenu>
          <el-menu-item v-else :key="navIndex + 1" :index="nav.route_url">
            <span> {{ nav.name }}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </el-aside>
    <el-main>
      <keep-alive>
        <router-view />
      </keep-alive>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: 'Serve',
  components: {},
  data () {
    return {
      activeIndex: this.$route.path,
      navs: [
        { name: '金融产品', route_url: '/serve/product' },
        { name: '政策服务', route_url: '/policy' },
        { name: '新闻中心', route_url: '/news' },
        {
          name: '活动专场',
          route_url: '/activity',
          children: [
            { name: '活动通知', route_url: '/activity/events', },
            { name: '活动新闻', route_url: '/activity/news', },
          ],
        },
        {
          name: '合作机构',
          route_url: '/serve/partners',
        },
      ],
    }
  },
  watch: {
    $route (to, from) {
      this.activeIndex = to.path
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .load-more-wrapper {
  padding: 10px;
}

::v-deep .el-submenu {
  .el-submenu__title i {
    top: 52%;
    right: 5%;
  }

  .el-menu-item {
    min-width: auto;
    padding-left: 30px !important;
  }
}
</style>
